<div class="p-3 mb-2 border-round shadow-2 flex align-items-center {{type}}"
[ngClass]="{'bg-white': isRead}">
 
  <div><span class="text-900 text-md font-medium mb-2 flex align-items-center gap-1">
    <div class="icon-wrapper  border-round inline-flex align-items-center justify-content-center mr-1">
      <i class="pi pi-users text-sm"></i>
    </div>
    Package is expiring</span>
    <p class="mt-1 mb-1 text-600 font-medium text-sm">Your package English A1 with
      Nickos is expiring on 12 December 2025</p>
    <p class="mt-3 mb-0 text-600 font-medium text-sm">
      <a class="cursor-pointer link border-4"><span>Extend
          Package</span></a></p>
  </div>
  <div class="ml-auto flex flex-column align-items-end">
    <p-button [link]="true" size="small" (click)="menu.toggle($event)" icon="pi pi-ellipsis-v" styleClass="p-0 w-1rem text-500" />
    <p-menu #menu appendTo="body" [popup]="true" [model]="items"></p-menu>
    <span class="text-400"> 14h </span>
  </div>
</div>